<template>
    <div class="layout">
        <div class="layout-header"></div>
        <div class="layout-banner"></div>
        <div class="container1500 mh500">
            <div class="layout-main-top"></div>
            <div class="layout-main-middle">
                <div class="title">
                    <p></p>
                    <p><span>公司简介</span></p>
                    <p></p>
                </div>
                <p class="title-en">Company profile</p>
                <h2>● 我们是...</h2>
                <p>
                    创环环保是深圳市一家致力于海绵城市建设解决方案的水环境处理技术公司，公司成立于2005年。主要从事低影响开发技术（LID）、防洪防涝、面源污染控制、雨洪利用、给水排水、水处理及地理信息系统（GIS）技术咨询业务.
                </p>
                <p>
                    我们采用低影响开发技术、流域管理技术、面源污染控制技术、洪峰控制技术、GIS技术、模型分析技术及水处理技术进行雨洪利用设计。
                </p>
                <p>我们提供雨水中水综合利用系统设计。</p>
                <p>
                    我们提供高效的低影响开发技术设施和合流制污水溢流控制技术设备。
                </p>
                <p>我们采用GIS技术、模型分析技术进行防洪排涝工程设计。</p>
                <h2>● 我们的责任...</h2>
                <p>
                    我们的责任是为水环境整治工程、暴雨管理及雨水利用工程、防洪排涝工程和环境、防灾、水务、土地利用GIS系统提供优秀的、创新性的专业解决方案。
                </p>
                <p>水环境整治：努力创造生态性与景观性合一的和谐整治方案；</p>
                <p>
                    暴雨管理、雨水利用及LID：1）提供雨水利用；2）削减面源污染；3）洪峰控制及4）水土保持的综合标准提供最优化的解决方案；
                </p>
                <p>中水利用：提供安全可靠的中水利用系统解决方案；</p>
                <p>防洪防涝：提供优秀、可行的防灾图及防灾方案；</p>
                <p>GIS系统：提供各种环境及水务GIS系统。</p>
                <h2>● 我们的服务...</h2>
                <p>提供高效的LID技术设施及CSO控制设备</p>
                <p>暴雨管理及雨水利用；</p>
                <p>中水利用；</p>
                <p>水环境整治；</p>
                <p>流域管理；</p>
                <p>专业的水务、防灾及土地利用地理信息系统。</p>
            </div>
        </div>
        <NavFooter />
    </div>
</template>
<script>
import NavFooter from "../../components/NavFooter.vue";

export default {
    name: "CompanyProfile",
    components: {
        NavFooter,
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/mixin.scss";
.layout {
    position: relative;
    width: 100%;
    z-index: 0;
    .layout-header {
        height: 100px;
    }
    .layout-banner {
        width: 100%;
        height: 350px;
        background: url("../../assets/img/banner1.jpg") no-repeat center;
        background-size: cover;
    }
    .mh500 {
        min-height: 500px;
        .layout-main-top {
            height: 40px;
            border-bottom: 1px solid #bcbcbc50;
        }
        .layout-main-middle {
            padding: 40px 0;
            min-height: 460px;
            box-sizing: border-box;
            .title {
                width: 100%;
                height: 39px;
                display: flex;
                justify-content: center;
                p {
                    width: 20px;
                    height: 19px;
                    font-size: 26px;
                    border-bottom: 1px solid #0e2686;
                }
                :nth-child(2) {
                    width: 426px;
                    height: 39px;
                    line-height: 39px;
                    text-align: center;
                    border-bottom: none;
                }
            }
            .title-en {
                width: 100%;
                height: 23.5px;
                line-height: 23.5px;
                font-size: 15px;
                color: #0e2686;
                text-align: center;
            }
            h2 {
                margin: 10px 0;
            }
            p {
                height: 40px;
                line-height: 40px;
                font-size: 15px;
            }
        }
    }
}
</style>